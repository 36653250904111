<template>
  <div class="mapbox-custom-marker">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 27 41"
      style="enable-background:new 0 0 27 41;"
      xml:space="preserve"
    >
      <g>
        <g transform="translate(3.0, 29.0)">
          <ellipse class="st0" cx="10.5" cy="5.8" rx="10.5" ry="5.3" />
          <ellipse class="st0" cx="10.5" cy="5.8" rx="10.5" ry="5.3" />
          <ellipse class="st0" cx="10.5" cy="5.8" rx="9.5" ry="4.8" />
          <ellipse class="st0" cx="10.5" cy="5.8" rx="8.5" ry="4.3" />
          <ellipse class="st0" cx="10.5" cy="5.8" rx="7.5" ry="3.8" />
          <ellipse class="st0" cx="10.5" cy="5.8" rx="6.5" ry="3.3" />
          <ellipse class="st0" cx="10.5" cy="5.8" rx="5.5" ry="2.9" />
          <ellipse class="st0" cx="10.5" cy="5.8" rx="4.5" ry="2.4" />
        </g>
        <g>
          <path
            :fill="color"
            d="M27,13.5c0,5.6-6.7,13.5-12.2,21c-0.7,1-1.8,1-2.5,0C6.7,27,0,19.2,0,13.5C0,6,6,0,13.5,0S27,6,27,13.5z"
          />
        </g>
        <g class="st2">
          <path
            d="M13.5,0C6,0,0,6,0,13.5c0,5.7,6.7,13.5,12.2,21c0.8,1,1.8,1,2.5,0C20.3,27,27,19.1,27,13.5C27,6,21,0,13.5,0z M13.5,1
			C20.4,1,26,6.6,26,13.5c0,2.4-1.5,5.7-3.8,9.2s-5.5,7.4-8.3,11.2c-0.2,0.3-0.3,0.4-0.4,0.5c-0.1-0.1-0.2-0.3-0.4-0.5
			c-2.8-3.8-5.6-7.6-8-11.1S1,16,1,13.5C1,6.6,6.6,1,13.5,1z"
          />
        </g>
        <g transform="translate(6.0, 7.0)"></g>
        <g transform="translate(8.0, 8.0)">
          <circle class="st3" cx="5.5" cy="5.5" r="10.2" />
          <circle class="st4" cx="5.5" cy="5.5" r="10.2" />
        </g>
        <rect x="6" y="6.8" class="st5" width="15" height="14.6" />
        <text
          :transform="`matrix(1 0 0 1 ${getMatrix(orderNumber)} 17.6248)`"
          class="st6 st7"
          :fill="color"
          style="font-family: 'Open Sans', Arial, Helvetica, sans-serif; font-weight: bold;"
        >
          {{ orderNumber }}
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'map-marker',
  props: {
    orderNumber: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: () => 'var(--primary)',
    },
  },
  methods: {
    getMatrix(orderNumber) {
      const realNumber = orderNumber;
      let matrix = 9.8782;

      if (realNumber >= 10) {
        matrix = 6.8782;
      }

      if (realNumber >= 100) {
        matrix = 4.3782;
      }

      return matrix;
    },
  },
};
</script>

<style lang="scss">
.mapbox-custom-marker {
  text {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  }
  width: 28px;
  .st0 {
    opacity: 4e-2;
    enable-background: new;
  }
  .st2 {
    opacity: 0.25;
  }
  .st3 {
    opacity: 0.25;
    enable-background: new;
  }
  .st4 {
    fill: #ffffff;
  }
  .st5 {
    fill: none;
  }
  .st6 {
    font-family: 'OpenSans-Bold';
  }
  .st7 {
    font-size: 10.6897px;
    width: 100%;
  }
}
</style>
